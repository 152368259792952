import { instance } from '@/utils/http.js'
import getMulFile from '@/utils/mulFile.js'
import Vue from 'vue'
import getFileMultiple from '@/utils/multiple_getfile.js'

export const upload = function (data) {
  return instance({
    url: "/api/v1/discuss/upload",
    method: 'post',
    Headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

/**
 * 一键上传文件+加密
 * @param {String} filename 文件名称
 * @param {Number} limit 文件大小限制
 * @param {String} fileType 文件类型
 * @returns
 */

export const uploadImageAuto = async function (filename, limit = 20, fileType = "*") {
  const res = await Vue.prototype.$get_file({ limit, fileType })
  let formData = new FormData()
  formData.append('filename', filename)
  formData.append(filename, res)
  formData.append('sign', Vue.prototype.$getMd5Form({ filename }))
  const data = await upload(formData)
  return data
}

//制作答题卡导入文件
export const uploadFileAuto = async function (filename, limit = 20, fileType = "*") {
  const res = await Vue.prototype.$get_file({ limit, fileType })
  let formData = new FormData()
  if (filename == "pdf") {
    formData.append('pdf_file_name', filename)
  } else if (filename == "excel") (
    formData.append('filename', filename)
  )
  formData.append(filename, res)
  formData.append('sign', Vue.prototype.$getMd5Form({ filename }))
  const data = await uploadExcel(formData)
  return data
}

export const uploadExcelAuto = async function (filename, limit = 20, fileType = "*", payload) {
  const res = await getFileMultiple({ limit, fileType })
  let formData = new FormData()
  formData.append("pdf_file_name", "pdf")
  formData.append("filename", "excel")

  if (res.length != 2) {
    Vue.prototype.$message.warning("请选择excel和pdf两个文件")
    return false
  }

  for (let index = 0; index < res.length; index++) {
    if (res[index].type.includes("pdf")) {
      formData.append("pdf", res[index])
    } else {
      formData.append("excel", res[index])
    }
  }
  for (const key in payload) {
    formData.append(key, payload[key])
  }

  payload['pdf_file_name'] = "pdf"
  payload['filename'] = "excel"
  formData.append('sign', Vue.prototype.$getMd5Form(payload))
  const data = await uploadExcel(formData)
  return data
}


export const uploadExcel = (data) => {
  return instance({
    url: "/api/v1/papers/sheet_excel_upload",
    method: 'post',
    Headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

//上传答题卡
export const uploadSheet = (data, onUploadProgress) => {
  return instance({
    url: "/api/v1/henan/upload_sheet",
    method: 'post',
    Headers: { 'Content-Type': 'application/json' },
    data,
    onUploadProgress: onUploadProgress

  })
}
export const uploadSheetFile = async function (filename, form, limit = 20, fileType = "image/*", uploadProgress) {

  const res = await getMulFile({ limit, fileType })
  let formData = new FormData()
  // console.log(res)
  let arr = []
  // for (let i of res) {
  //   arr.push(i.name)
  //   formData.append(i.name, i)
  //   // formData.append(`filenames${i}`, i.name)
  // }
  for (let i = 1; i <= res.length; i++) {
    arr.push("file_" + i)
    formData.append(arr[i - 1], res[i - 1])
  }
  formData.append('filenames', arr)
  formData.append('edu_paper_id', form.edu_paper_id)
  formData.append('type', form.type)
  formData.append('progress', form.progress)
  formData.append('sign', Vue.prototype.$getMd5Form({ ...form, 'filenames': arr.join(',') }))
  const data = await uploadSheet(formData, uploadProgress)
  return data
}

//重新上传识别图片
export const againSheet = (data, onUploadProgress) => {
  return instance({
    url: "/api/v1/henan/again_audit_sheet",
    method: 'post',
    Headers: { 'Content-Type': 'multipart/form-data' },
    data,
    onUploadProgress
  })
}
export const uploadAgainSheet = async function (params, limit = 20, fileType = "image/*", onUploadProgress) {
  const res = await Vue.prototype.$get_file({ limit, fileType })
  let formData = new FormData()
  //获取params的属性名和值
  const keys = Object.keys(params)
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = params[key]
    formData.append(key, value)
  }

  formData.append(params.file_name, res)
  formData.append('sign', Vue.prototype.$getMd5Form(params))
  const data = await againSheet(formData, onUploadProgress)
  return data
}
