import { instance } from '@/utils/http.js'

// 保存答题卡内容
export const saveSheet = function (data) {
  return instance({
    url: '/api/v1/papers/paper_sheet',
    method: 'POST',
    data
  })
}

// 继续制作答题卡
export const continueMake = function (params) {
  return instance({
    url: '/api/v1/papers/paper_sheet_detail',
    method: 'get',
    params
  })
}

// 申请制作答题卡
export const applyMake = function (data) {
  return instance({
    url: '/api/v1/papers/applicant',
    method: 'post',
    data
  })
}
// 取消申请
export const cancelApplyMake = function (data) {
  return instance({
    url: '/api/v1/papers/cancel_applicant',
    method: 'post',
    data
  })
}

//未审核答题卡列表
export const getSheetList = function (params) {
  return instance({
    url: '/api/v1/henan/answer_list',
    method: 'get',
    params
  })
}

//答题卡识别后数据
export const auditSheet = function (data) {
  return instance({
    url: '/api/v1/henan/audit_sheet',
    method: 'post',
    data
  })
}

//答题卡上传进度
export const uploadSheetSchedule = function (params) {
  return instance({
    url: '/api/v1/test/upload_sheet_schedule',
    method: 'get',
    params
  })
}


//答题卡已审核详情
export const sheetDatail = function (params) {
  return instance({
    url: '/api/v1/henan/sheet_detail',
    method: 'get',
    params
  })
}



//确认答题卡
export const confirmSheet = function (data) {
  return instance({
    url: '/api/v1/henan/confirm_sheet',
    method: 'post',
    data
  })
}

/**
 *  筛选答题卡模板
 */
export const getSheetTemplate = function (params) {
  return instance({
    url: '/api/v1/papers/sheet_template',
    method: 'get',
    params
  })
}

/**
 *  测试答题卡
 */
export const getTestCardInfo = function (data) {
  return instance({
    url: '/api/v1/test/audit_test_sheet',
    method: 'post',
    data
  })
}

//单条识别答题卡
export const singleAuditSheet = function (data) {
  return instance({
    url: '/api/v1/papers/det_confirm',
    method: 'post',
    data
  })
}
//查询识别答题卡任务装态
export const taskStatus = function (params) {
  return instance({
    url: '/api/v1/papers/task_status',
    method: 'get',
    params
  })
}

//获取异步识别任务id

export const detBatchTaskid = function (params) {
  return instance({
    url: '/api/v1/papers/det_batch_taskid',
    method: 'get',
    params
  })
}

